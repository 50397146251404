import React, { useState } from 'react';
import styled from 'styled-components';
import { TRUST_FOR_KYC_AML_ACCORDION_CONTENTS } from '../utilities';
import CustomAccordion from '../components/CustomAccordion';

const Wrapper = styled.section`
    background-color: #050521;
    padding: 82px 0;
    margin-top: 110px;

    @media only screen and (max-width: 1024px) {
        padding: 36px 0;
        margin-top: 72px;
    }

    @media only screen and (max-width: 560px) {
        padding: 36px 0;
        margin-top: 54px;
    }
`;

const Contents = styled.div`
    max-width: 1280px;
    margin: auto;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    gap: 64px;

    @media only screen and (max-width: 850px) {
        gap: 24px;
    }

    @media only screen and (max-width: 560px) {
        padding: 0 16px;
    }
`;

const Heading = styled.h1`
    color: #fff;
    font-size: 50px;
    line-height: 70px;
    font-weight: 400;

    > i {
        font-weight: 500;
    }

    @media only screen and (max-width: 850px) {
        font-size: 28px;
        line-height: 28.9px;
    }

    @media only screen and (max-width: 560px) {
        font-size: 24px;
        line-height: 33.6px;
    }
`;

const Main = styled.div`
    display: flex;

    @media only screen and (max-width: 1024px) {
        gap: 56px;
    }

    @media only screen and (max-width: 850px) {
        gap: 30px;
    }

    @media only screen and (max-width: 560px) {
        flex-direction: column;
        gap: 24px;
    }
`;

const LeftContent = styled.div`
    flex: 1;
`;

const Para = styled.p`
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    line-height: 26px;
    max-width: 512px;

    @media only screen and (max-width: 850px) {
        font-size: 12px;
        line-height: 18px;
        max-width: 304px;
    }

    @media only screen and (max-width: 560px) {
        max-width: 100%;
    }
`;

const RightContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const TrustAuthenticateSection = () => {
    const [openAccordionIndex, setOpenAccordionIndex] = useState(0);

    const handleToggleAccordion = (index) => {
        setOpenAccordionIndex((prevIndex) => (prevIndex === index ? -1 : index));
    };

    return (
        <Wrapper>
            <Contents>
                <Heading>
                    Trust Authenticate to Ensure <i>Faster KYC</i> and <i>Enhanced AML</i>{' '}
                    Compliance
                </Heading>
                <Main>
                    <LeftContent>
                        <Para>
                            Our comprehensive suite of KYC/AML products is crafted to address the
                            dynamic challenges of sophisticated financial crimes, money laundering,
                            fraud, and identity theft. Authenticate is committed to delivering a
                            data-driven, customisable platform for seamless, automated KYC and AML
                            monitoring, empowering compliance teams with complete control and
                            efficiency.
                        </Para>
                    </LeftContent>
                    <RightContent>
                        {TRUST_FOR_KYC_AML_ACCORDION_CONTENTS?.map((data, index) => (
                            <CustomAccordion
                                key={data.id}
                                data={data}
                                isOpen={openAccordionIndex === index}
                                toggleAccordion={() => handleToggleAccordion(index)}
                            />
                        ))}
                    </RightContent>
                </Main>
            </Contents>
        </Wrapper>
    );
};

export default TrustAuthenticateSection;
